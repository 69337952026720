.signup {
  background: #1A2038;
  height: auto;
  min-height: 100vh;
  
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    background-color: #fff;
  }

  .MuiOutlinedInput-input {
    padding: 18.5px 14px;
    background-color: #fff;
    border-radius: 15px;
  }

  .MuiFormControl-root {
    background-color: #1A2038 !important;
  }

  .signup-card {
    max-width: 800px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }
    background-color: #1A2038;
    text-align: -webkit-center;
  }

  .signup-text-title {
    // color: gold !important;
    color: #fbd52a !important;
    font-family: -webkit-body;
    letter-spacing: 1.5px;
    text-shadow: 1px 1px #999, 2px 2px #888, 3px 3px #777;
    transform: rotateX(15deg);
  }

  .signup-title {
    width: 60px;
    height: 90px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 15px;
  }

  .MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 15px;
}

  .signin-input {
    border-radius: 15px;
  }

  .signup-card-customs {
    max-width: 1000px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }
  }

  .MuiSvgIcon-root {
    position: absolute;
    z-index: 1;
    margin-top: -113px;
    margin-left: 180px;
  }

.MuiButton-contained {
  background-color: goldenrod;
  width: 100%;
  height: 50px;
  border-radius: 15px;
}

.MuiButton-contained:hover {
  background-color: darkgoldenrod;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(15px, -15px) scale(0.9);
}
}

.primary{
  background-color: #c1b398;
}
