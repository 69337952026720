.layout2 .topbar {
  padding-top: 0;
  padding-bottom: 0;
  background-image: url('/assets/images/sidebar/trongdong.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.layout2 .topbar .brand .brand__text {
  font-weight: 550;
  font-size: 1.5rem;
  margin: 0 1rem;
  color: #0f4871;
  font-family: 'Roboto';
  line-height: 24px;
}

.horizontal-nav a,
.horizontal-nav label {
  padding: 4px 10px !important;
  color: rgba(0, 0, 0, .5) !important;
  font-size: .9rem !important;
  font-weight: 550 !important;
}

.layout2 .navbar {
  background: linear-gradient(90deg, #cab797, #f7e2b9, #c0aa84);
}

.navbar .horizontal-nav ul ul {
  background: #fff;
}

.layout2 .topbar .brand img {
  height: 60px;
}

.layout2 .topbar .brand img {
  height: 60px;
}

.layout2 .MuiTableRow-root .MuiTableCell-head,
.table-header-customer,
.table-header-customer .MuiTableCell-head {
  color: #fff !important;
  font-weight: bold;
}

.MuiTableCell-head {
  background-color: #466e8c !important;
  color: white !important;
}

.MuiTableRow-root .MuiTableCell-head {
  padding-left: 20px;
  padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-root {
  padding-left: 20px;
  padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-paddingNone {
  padding: 0px;
}

.MuiIconButton-root {
  cursor: pointer;
}

.fieldSelect .MuiButton-root {
  padding: 3px 16px
}

.fieldSelect .MuiOutlinedInput-input {
  padding: 0 0 0 8px !important
}

.fieldSelect.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0 14px 0 8px !important;
}

.asset_department {
  background-color: #fff;
  padding: 8px;
  height: auto;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 20%;
}

.w-82 {
  width: 82%;
}

.status {
  display: flex;
  justify-content: space-between;
}


.margin-auto {
  margin: auto;
}

.none_wrap {
  white-space: nowrap !important;
}

.validator-form-scroll-dialog {
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important;
}

.logo-login {
  max-width: 200px !important;
}

.logo-login img {
  width: 100% !important;
}

.tb-charts-container .tb-charts-tab-label button {
  font-size: 0.6rem;
  max-width: 25%;
}

.tb-charts-container .tb-charts-tab-label .MuiTab-root {
  min-width: unset;
}

// .logo-menu{
//     max-width: 100px !important;
// }
// .logo-menu img{
//     width: 100% !important;
// }

.map-show {
  position: relative;
  z-index: 9;
}

.search-card {
  position: absolute;
  top: 90px;
  right: 5px;
  z-index: 10;
  border-radius: 5px;
  width: 250px;
}

iframe {
  display: block;
  margin: 0 auto;
  padding: 0;
  /* iframes are inline by default */
  background: #fff;
  border: none;
  /* Reset default border */
  height: 100vh;
  /* Viewport-relative units */
  width: 100vw;
}

// }

// Openlayer popup
.ol-popup {
  z-index: 0;
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "?";
}

.styleColor {
  background-color: #358600;
  color: #fff;
}

.close-style {
  position: absolute;
  right: 20px;
  top: 7px;
  font-size: small;
  width: 15px;
  height: 15px;
  color: #ebebeb;
}

#atmospheric-c {
  height: 500;
  margin: 0 auto;
}

.MuiSelect-select.MuiSelect-select {
  color: black !important;
}

.MuiInputBase-input.Mui-disabled {
  color: black !important;
}

.div-parent-chart {
  min-height: 400px;
}

.div-child-chart {
  text-align: center;
}

.lable-typeOrganization {
  font-size: 14px !important;
  transform: translate(0, 1.5px) scale(0.75) !important;
  transform-origin: top left !important;
}

.float-left {
  float: left;
  // padding-top: 40px;
}

.float-right {
  float: right;
  // padding-top: 25px;
}

.footer.flex.flex-middle {
  background: #466e8c;
  color: #ffffff;
}

.container.p-0 {
  background-color: whitesmoke;
}

.MuiIcon-colorPrimary {
  color: #466e8c !important;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  color: #ffffff;
  background-color: #466e8c !important;
}

//luôn để cuối cùng của file css
@media screen and (min-width: 1900px) {
  .layout2 .container {
    max-width: 1700px;
    margin-top: 0px;
  }

  .layout2 .horizontal-nav ul li ul a {
    white-space: nowrap;
  }

  .layout2 .horizontal-nav ul ul li {
    width: auto;
  }

  .layout2 .horizontal-nav ul ul ul {
    top: 0;
    position: absolute;
    left: 100%;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1400px) {
  .layout2 .container {
    max-width: 1400px;
    margin-top: 0px;
  }

  .layout2 .horizontal-nav ul li ul a {
    white-space: nowrap;
  }

  .layout2 .horizontal-nav ul ul li {
    width: auto;
  }

  .layout2 .horizontal-nav ul ul ul {
    top: 0;
    position: absolute;
    left: 100%;
  }
}

@media screen and (max-width: 600px) {
  .export_excel {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 600px) {
  .export_excel {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 959px) and (min-width: 600px) {
  .export_excel {
    padding-top: 60px !important;
    text-align: -webkit-left !important;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .layout2 .container {
    margin-top: 0px;
  }

  .layout2 .horizontal-nav ul li ul a {
    white-space: nowrap;
  }

  .layout2 .horizontal-nav ul ul li {
    width: auto;
  }

  .layout2 .horizontal-nav ul ul ul {
    top: 0;
    position: absolute;
    left: 100%;
  }
}

.plan_text {
  float: right;
  // width: 70%;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 5px;
}

.float-left .MuiOutlinedInput-adornedEnd {
  width: 120px;
}

.body_content {
  margin-top: 20px;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-grid-sm-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12 {
  justify-content: space-around;
}

.mb-sm-30 {
  margin-bottom: 20px;
}

.container_1 {
  padding: 15px;
}

.bbb {
  background-color: red;
}

.menu>li:hover {
  background-color: #c1b398;
}

.horizontal-nav ul li ul li:hover,
.horizontal-nav ul li ul li.open {
  background: #c1b398 !important;
}

.static_1 {
  background-color: #72b1db;
  padding: 15px;
  border-radius: 25px;
  color: #fff;
  // max-height: 150px;
  flex-grow: 0.4;
  margin-bottom: 15px;
}

.static_2 {
  background-color: #cc5757;
  padding: 15px;
  border-radius: 25px;
  color: #fff;
  // max-height: 150px;
  flex-grow: 0.4;
  margin-bottom: 15px;

}

.static_3 {
  background-color: #8b61b5;
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  max-height: 150px;
  max-width: 320px;
}

.static_4 {
  background-color: #af7d56;
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  max-height: 150px;
  max-width: 320px;
}

.h4_size {
  color: #fff !important;
  text-align: center;
}

.none_align {
  text-align: left;
}

.item_2 {
  padding-left: 30px;
  border-left: 1px solid;
  text-align-last: start;
}

.item_1>p {
  font-size: larger;
  margin-top: 5px;
  margin-bottom: 10px;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-6.MuiGrid-grid-md-6.MuiGrid-grid-lg-6 {
  text-align: center;
}

.text_inline {
  float: left;
}

.btn_right {
  text-align: -webkit-right;
  margin-bottom: 10px !important;
}

.btn_appro {
  margin-bottom: 5px !important;
}

.tb_mt {
  margin-top: 30px !important;
}

.export_excel {
  margin-top: 10px;
  text-align: right;
}

.btn_exp_exc {
  margin-left: 8px !important;
  text-align: left;
}

.hideText {
  color: #fff;
}

.planAp1 {
  margin-left: 0px !important;
}

.planAp2 {
  // margin-left: -5px !important;
  margin-top: 48px !important;
}

.planAppNoDup1 {
  padding-top: 48px !important;
}

.planAppNoDup2 {
  text-align-last: center;
}

.planAppNoDup3 {
  padding-top: 55px !important;
}

.dialog_title {
  text-align: -webkit-center;
}

.btn_actions {
  align-self: center;
}

.title_dialog {
  text-align: -webkit-center;
}

.title_dialog p {
  width: 100%;
  font-size: 18px;
  margin: 10px 0 6px 0;
}

.inspection.label {
  color: white;
  padding: 5px;
  white-space: nowrap;
}

.success {
  background-color: #04aa6d;
}

/* Green */
.info {
  background-color: #2196f3;
}

/* Blue */
.warning {
  background-color: #ff9800;
}

/* Orange */
.danger {
  background-color: #f44336;
}

/* Red */
.other {
  background-color: #e7e7e7;
  color: black !important;
}

.btn_left {
  text-align: -webkit-left;
}

.btn_add {
  color: #0ddd10;
}

.btn_remv {
  color: red;
}

.p-16 {
  padding: 8px;
}

/* Gray */
.resultPerform {
  text-align: center !important;
}

.resultPerform .MuiCheckbox-colorSecondary.Mui-checked {
  color: black;
}

.inspection.checkBox {
  cursor: pointer;
  color: white;
  padding: 5px;
  min-width: 100px;
}

.inspection.checkBox.follow {
  background-color: rgb(0, 204, 0);
}

.inspection.checkBox.conclude {
  color: #000;
}

.info_mt_cent {
  padding-right: 10px;
  text-align: center;
  margin-top: 20px !important;
}

.info_border1 {
  border: 1px solid green;
  padding: 5px;
}

.info_border2 {
  border: 1px solid red;
  padding: 5px;
}

.info_border3 {
  border: 1px solid cyan;
  padding: 5px;
}

.info_border4 {
  border: 1px solid blue;
  padding: 5px;
}

.info_border5 {
  border: 1px solid orange;
  padding: 5px;
}

.btn_s_right {
  float: right;
}

.btn-customer-base {
  padding: 10px;
  cursor: pointer;
  color: #fff
}

.btn-customer-base:hover {
  color: #333;
}

.excel_ico {
  width: 21px;
}

.MuiTableCell-paddingNone {
  width: 30px !important;
  text-align: -webkit-center !important;
}

.sub_table {
  min-height: 200px;
  width: 101%;
  margin-left: -5px;
  margin-top: -8px;
}

.sub-table {
  min-height: 200px;
  width: 100%;
  margin-left: -5px;
  margin-top: -30px;
}

.sub_header {
  background-color: gray !important;
}

.MuiTableCell-alignLeft {
  text-align-last: left;
}

// .MuiToolbar-gutters>div {
//   display: none !important;
// }

#mui-99560 {
  display: none !important;
}

.MuiToolbar-gutters {
  float: left !important;
}

.MuiTablePagination-selectRoot {
  display: none !important;
}

.status_div {
  padding: 5px;
  color: white;
  background-color: #0a6fff;
  text-align: -webkit-center;
}

.enterprise_name {
  color: #2196f3;
}

.time_span {
  font-weight: bold !important;
}

.update_dialog_title {
  background-color: #efefef !important;
}

.inspectionsOrg-render {
  margin-top: 5px;
  color: "#4cd14b";
}

.inspectionsOrg-render>span:first-of-type {
  position: absolute;
}

.inspectionsOrg-icon {
  position: relative;
}

.inspectionsOrg-render .MuiSvgIcon-root {
  width: 18px;
  height: auto;
  color: #4cd14b;
}

.inspectionsOrg-render .render-name {
  color: #08b8f1;
}

.isUsing-render {
  margin-top: 5px;
  color: "#4cd14b";
}

.isUsing-render>span:first-of-type {
  position: absolute;
}

.isUsing-icon {
  position: relative;
}

.isUsing-render .MuiSvgIcon-root {
  width: 18px;
  height: auto;
  color: #08b8f1;
}

.isUsing-render .render-name,
.inspectionsOrg-render .render-name {
  padding-left: 20px;
  font-weight: bold;
}

.form-f {
  margin-top: 4px !important;
}

.form-f1 {
  margin-top: 20px !important;
}

.MuiTableCell-paddingNone:last-child {
  background-color: #e0e0e0;
}

.align-center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.table-customer-border .MuiTableRow-head,
.table-customer-border .MuiTableRow-root .MuiTableCell-head,
.table-customer-border .MuiTable-root,
.table-customer-border .MuiTableRow-root {
  border: 2px solid;
  font-weight: bold;
}

.table-customer-border .MuiTableRow-root .MuiTableCell-root {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

.table-customer-border .MuiTableCell-body {
  border: 1px solid;
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.cus-label {
  color: white;
  padding: 5px;
  font-family: Arial;
}

.cus-label.info {
  background-color: #2196f3;
}

.btn-rlo {
  margin-top: 47px !important;
}

.text-align-center {
  text-align: center;
}

.text-align-end {
  text-align: end;
}

.vertical-align-bottom {
  .MuiInputBase-input {
    padding: 1px;
  }

  vertical-align: bottom !important;
}

.date {
  width: 30px;
}

.year {
  width: 50px;
}

.MuiTableCell-paddingNone>b {
  display: none !important;
}

// .MuiTableCell-paddingNone{
//   font-size: 1rem !important;
//   font-weight: bold !important;
// }

.cd-dialog5 {
  width: 300px;
}

.cd-dialog6 {
  width: 700px;
}

.cd-dialog7 {
  width: 700px;
}

.cd-dialog98 {
  width: 647px;
}

.cd-dialog9 {
  width: 600px;
}

.cd-dialog1 {
  width: 700px;
}

.cd-dialog2 {
  width: 620px;
}

.cd-dialog3 {
  width: 700px;
}

.cd-dialog {
  width: 800px;
}

.cd-dialog5-1 {
  width: 545px;
}

.cn-dialog-hr {
  width: 150px;
  font-weight: bold;
}

.cn-dialog3 {
  width: 400px;
}

.cn-dialog3-1 {
  width: 400px;
}

.cn-dialog4 {
  width: 400px;
}

.cn-dialog-ct {
  width: 450px;
}

.cr-dialog-ct {
  width: 350px;
}

.cr-dialog5 {
  width: 350px;
}

.cr-dialog3 {
  width: 720px;
}

.cr-dialog8 {
  width: 820px;
}

.cr-dialog4 {
  width: 250px;
}

//result-dialog
.result-dialog {
  line-height: 2;
  padding: 0 30px;
}
.result-dialog .MuiInput-root {
  padding-top: 5px;
}

.result-dialog h5 .MuiInput-root {
  padding-top: 0;
}

.result-dialog .MuiInputBase-input {
  padding: 0 0 0 5px;
}

.btn {
  color: #fff;
  background-color: rgb(179, 147, 90);
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 18px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.btn-success {
  color: #fff !important;
  background-color: #5cb85c !important;
  // border-color: #4cae4c;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44 !important;
  border-color: #398439;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

//end-result-dialog
.object-report .MuiDialog-paperWidthLg {
  max-width: calc(100% - 64px);
}

.mb-5 {
  margin-bottom: 5px !important;
}

#date-picker-dialog-helper-text {
  display: none;
}

.count-result {
  margin-left: 5px;
  font-size: 15px;
}

.dialog-result-admin {
  margin-bottom: 25px !important;
  padding-bottom: 25px !important;
  border-bottom: 1px solid black;
}

.title-dialog-follow {
  margin-top: 20px;
}

.follow-up-filter {
  margin-left: 1px !important;
}

.table-css {
  border: 1px #000 solid !important;
}

.errorMesseges {
  margin-left: 16px;
  margin-top: 4px;
  font-size: 0.75rem;
  color: rgb(255, 39, 39);
}

.insFile {
  cursor: pointer;
}

.action-fixed {
  top: 0;
  left: 0;
  position: sticky;
  z-index: 20;
  background-color: whitesmoke;
}

.inline-block {
  display: inline-block;
}

.check_date_message__error {
  color: #f44336;
  margin-top: 5px;
  font-size: 12.5px;
}

.check_message__error_blank {
  color: #f44336;
  margin: 0px 0 0 10px;
  font-size: 12.5px;
}

.pt-10 {
  padding-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.pr-7 {
  padding-right: 7px;
}

.p-7 {
  padding: 0 7px;
}

.pr-20 {
  padding-right: 20px;
}

.color-text-table {
  color: #e0e0e0 !important;
}

.MuiGrid-item {
  width: 100%;
}

.MuiGrid-spacing-xs-3 {
  margin: 0px !important;
}

.MuiGrid-spacing-xs-2 {
  margin: 0px !important;
  width: calc(100%) !important;
}

.open-track-document .MuiInputBase-input {
  padding: 0 0 2px 0;
}

.open-track-document .item-content {
  border: 1px solid black;
  padding: 4px 12px;
  margin-top: 4px;
}

.open-track-document h5 {
  margin-bottom: 0;
}

.signature-conclusion .MuiInputBase-input {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.item-decision {
  border: 2px solid black;
  margin-bottom: 8px;
  padding: 8px;
}

.update-progress .MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
}

.update-progress .MuiTab-textColorInherit.Mui-selected span {
  scale: 1.1;
  padding: 4px 20px 0;
}

.update-progress .MuiTab-root:hover {
  opacity: 1;
}

.update-progress .MuiTab-wrapper {
  padding: 4px 12px 0;
}

.update-progress .MuiTab-root {
  color: #ffffff;
  background-color: #b3935a;
  padding: 0 !important;
  min-height: 36px;
}

.update-progress .MuiTabs-root {
  min-height: 36px;
}

.button-tabs {
  opacity: 1;
}

.add-tab {
  margin-top: 16px;
}

.administration-suggest-status {
  background-color: red;
  padding: 2px 4px;
  color: #FFFFFF;
  margin-left: 4px;
}

.select-date__follow .MuiIconButton-root {
  padding: 0;
}

.other-recomment {
  padding: 8px;
  border: 2px solid black;
}

.other-recomment:nth-of-type(n+2) {
  border-left: none;
}

.other-recomment__item {
  padding: 4px 0 0 12px;
}

.status-suggestion .MuiSelect-outlined.MuiSelect-outlined {
  padding: 2px 32px 0 8px;
}

.button-close-dialog {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.validatorForm-dialog {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.font-inherit {
  font-family: inherit;
}

.cursor-move {
  cursor: move;
}

.btn-close-dialog {
  position: absolute;
  right: 10px;
  top: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.color-red {
  color: red;
}

.h6 {
  margin: 0 0 0.5rem;
  line-height: 1.1;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.custom-autocomplete {
  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1)
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 6px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 4.5px 4px;
  }
}

.form-scroll-y {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.w-percent-84 {
  width: 84%;
}

.h-400 {
  height: 400px;
}

.min-w-250 {
  min-width: 250px;
}

.font-bold {
  font-weight: bold;
}

.input-status-dialog {
  padding: 0 14px !important;
}

.w-full {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.mt-10 {
  margin-top: 10px;
}

.gap-10 {
  gap: 10px;
}

.w-1\/2 {
  width: 50%;
}

.font-size-12 {
  font-size: 12px;
}

.error .ql-container.ql-snow , 
.error .ql-toolbar.ql-snow{
  border-color: red !important;
}

.py-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.bg-slate {
  background-color: rgb(203, 213, 225) !important;
}

.bg-yellow-warm {
  background-color: rgb(255, 217, 61) !important;
}

.bg-lime {
  background-color: rgb(190, 242, 100) !important;
}

.bg-orange-warm {
  background-color: rgb(255, 145, 48) !important;
}

.bg-cyan {
  background-color: rgb(0, 169, 255) !important;
}

.bg-teal {
  background-color: rgb(70, 194, 203) !important;
}

.bg-light-brown {
  background-color: rgb(192, 130, 97) !important;
}

.bg-gray {
  background-color: rgb(169, 169, 169) !important;
}

.cursor-not-alower {
  cursor: not-allowed !important;
}

.badge-success {
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 50%;
  background-color: #0ddd10;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
}